import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      Скоро здесь появится что-то интересное
      <iframe
        title="spotify"
        style={{borderRadius: '12px' }} src="https://open.spotify.com/embed/track/7AkprUKtXRybOAERV2Jqyy?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    </div>
  );
}

export default App;
